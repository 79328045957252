// /store/actions.js
import { MatjrahAPI, MatjrahClient } from "@/archive";

export default {
  counterUp({ state, commit }) {
    commit("setCounter", state.counter + 1);
  },
  updateStatusApplication({ commit }, payload) {
    commit("updateStatusApplication", payload);
  },
  setLocalStories({ commit }, payload) {
    commit("setLocalStories", payload);
  },
  async getShopData({ commit }) {
    const categories = await MatjrahAPI.data.getCategories();
    const manufacturers = await MatjrahAPI.data.getManufacturers();
    commit("setCategories", categories);
    commit("setManufacturers", manufacturers);
  },
  async getApplicationByID({ commit }, payload) {
    MatjrahClient.currentAppID = payload.RequestId // Set App ID to use it in data requests
    const data = await MatjrahAPI.app.getAppByID({
      app_id: payload.RequestId,
    });

    commit("setApplication", data);
    return data;
  },
  async updateApplication({ commit }, payload) {
    const data = await MatjrahAPI.app.updateApp(payload);

    commit("setApplication", data);
    return data;
  },

  async createApplication({ commit }, payload) {
    const data = await MatjrahAPI.app.createApp(payload);

    commit("setApplication", data);
    return data;
  },
};
