/**
 * Matjrah Client
 */
export class MatjrahClient {
	static currentAppID = 1;
	/**
	 * Pagination Limit
	 */
	static limit = 12;

	/**
	 * Set API Default Headers
	 * @example
	 * const myDefaultHeaders = {
	 * "Accept": "application/json",
	 * "Content-Type": "application/json",
	 *  }
	 * MatjrahClient.defaultHeaders = myDefaultHeaders
	 */
	static defaultHeaders = {
		"x-client": "web", // mobile
		'Accept': 'application/json, */*',
		"Content-Type": "application/x-www-form-urlencoded",
		'Accept-Encoding': 'gzip,deflate,br',
	};

	/**
	 * Set onResponseStatus handler
	 *  to invoke your custom functions in certain response status codes
	 * @example
	 * MatjrahClient.onResponseStatus = (status, resText) => {
	 *
	 *  switch(status) {
	 *
	 *  case 403:
	 *      // Do something, etc Clear UserData, Remove Auth Headers.
	 *      break
	 *
	 *  case 500:
	 *      // Do something else.
	 *      break
	 *
	 *  default:
	 *      // Unhandled cases
	 *      // console.log("Unhandled case for status ", status)
	 *
	 *  }
	 *
	 * }
	 */
	static onResponseStatus(status, resText) { }

	/**
	 * Append New Header (name, value) pair
	 * @example // Usage Example
	 * MatjrahClient.appendHeader("Authorization", "Bearer xxxxx")
	 * @param headerKey Header Name
	 * @param headerValue Header Value
	 */
	static appendHeader(headerKey, headerValue) {
		if (typeof headerKey !== "string" || typeof headerValue !== "string") {
			throw Error("type of headerKey & headerValue should be both strings!");
		}

		this.defaultHeaders = {
			// Old Default Headers
			...this.defaultHeaders,
			// New Header name and value pair
			[headerKey]: headerValue
		};
	}

	/**
	 * Remove header from default headers
	 * @example // Usage Example
	 * MatjrahClient.removeHeader("Authorization")
	 * @param headerKey Header Key to remove
	 */
	static removeHeader(headerKey) {
		if (typeof headerKey == "string") {
			delete this.defaultHeaders[headerKey];
		} else {
			throw Error("type of headerKey should be string!");
		}
	}
}
