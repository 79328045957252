
import { globalMixins } from "@/plugins/mixins.js";

export default {
  middleware: "isAuth",
  name: "DefaultLayout",
  data() {
    return {
      loading: true,
    };
  },
  mixins: [globalMixins],
  mounted() {
    this.$nextTick(() => {
      this.loading = false;
    });
  },
  watch: {
    "$auth.loggedIn": {
      handler(value) {
        if (value) {
          this.$store.dispatch("user/setUser");
        }
      },
      immediate: true,
    },
    $route: {
      handler() {
        const isLTR = this.$route.path.includes("en");
        this.$vuetify.rtl = !isLTR;
        this.isRight = !isLTR;
      },
      immediate: true,
    },
  },
};
