// /store/mutations.js
export default {
  setCounter(state, val) {
    state.counter = val
  },
  setCategories(state, val) {
    state.categories = val
  },
  setManufacturers(state, val) {
    state.manufacturers = val
  },
  setApplication(state, val) {
    state.application = val
  },
  updateStatusApplication(state, val) {
    state.application.draft_config.update_status = val
  },
  setLocalStories(state, val) {
    state.localStories = val
  },
}