import { request } from "../request";

export default { 
    listAllApps: () => request(`apps`),
    getAppByID: ({ app_id }) => request(`apps/${app_id}`, "GET"),
    // updateApp: ({ app_id, _method, draft_config, theme_id }) => request(`apps/${app_id}`, "POST", { _method, draft_config, theme_id }),
    updateApp: (params) => request(`apps/${params.app_id}`, "POST", params.body),
    createApp: (params) => request(`apps/`, "POST", params.body),
    deployApp: (app_id) => request(`deployRequest`, "POST", { app_id }),
    appPublish: ({ app_id }) => request(`apps/${app_id}/publish`, "POST", {}),
    uploadFile: ({ url }) => request(`upload`, "POST", { url }),
};