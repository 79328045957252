import { MatjrahAPI, MatjrahClient } from "@/archive";
export default {
  actions: {
    setUser() {
      if (this.$auth.loggedIn) {
        const auth_token = this.$cookies.get("auth._token.local");
        MatjrahClient.appendHeader("Authorization", `${auth_token}`);
      }
    },
  },
}
