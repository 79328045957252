import { MatjrahClient as Client } from "./client";

export const request = async (
	resourceURL,
	method = "GET",
	requestBodyOrQuery = null,
	 requestHeaders = { 'access-token': 'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY0MTIwMjA3MywiaWF0IjoxNjQxMjAyMDczfQ.PH5Klj9shojhUBxUTSEkyBzp07KJ1Q8M6J40qZrAxVQ' }
	// requestHeaders = {}
) => {
	let baseURL = process.env.BASE_URI_API

	// let requestURL = "https://testdashboard.matjrah.com/api/" + resourceURL;
	let requestURL = baseURL + resourceURL;

	
	
	const headers = {
		...Client.defaultHeaders,
		...requestHeaders,
	};

	// Fetch RequestDetails
	const requestDetails = { method, headers };

	if (requestBodyOrQuery) {
		if (method == "GET") {
			requestURL += convertObjToParams(requestBodyOrQuery);
		} else {
			requestDetails.body = new URLSearchParams(requestBodyOrQuery);
		}
	}

	// Main Step, Wait for fetch response.
	let response;
	try {
		response = await fetch(requestURL, requestDetails);
	} catch (error) {
		throw Error(`${resourceURL}: ${error.message} `);
	}

	const responseText = await response.text();

	if (response.ok) {
		// Request Succeeded
		return JsonParse(responseText).data;
	}

	// Request Failed For Some Reason

	// Call onResponseHandler for auth and server status handling
	Client.onResponseStatus(response.status, responseText);
	throw Error(JsonParse(responseText).message);
};

// Request Helpers
const convertObjToParams = (object = {}) =>
	`?${Object.keys(object)
		.filter((key) => !!object[key]) // Remove nulls or empty values
		.map((key) => `${key}=${object[key]}`)
		.join("&")}`;

const JsonParse = (string) => {
	try {
		// Try to parse response as JSON
		const json = JSON.parse(string);
		return json;
	} catch (error) {
		// Throw Unique Error Message on JSON Parse Failed
		throw Error("Unexpected Error Occured");
	}
};
