export const globalMixins = {
    watch: {
        $route: {
            // Detecting Website Language
            handler() {
                const isLTR = this.$route.path.includes("en")
                this.$vuetify.rtl = !isLTR;
                this.isRight = !isLTR;
            },
            immediate: true,
        },
    },
};
