import { MatjrahClient } from "../client";
import { request } from "../request";

export default {
  getCategories: () =>
    request(`storeData/categories/${MatjrahClient.currentAppID}`),
  getManufacturers: () =>
    request(`storeData/brands/${MatjrahClient.currentAppID}`),
  getInformation: () =>
    request(`storeData/information/${MatjrahClient.currentAppID}`),
  getProductById: ({ product_id }) =>
    request(
      `storeData/productDetails/${MatjrahClient.currentAppID}?product_id=${product_id}`
    ),
  searchProducts: ({ searchKeyword }) =>
    request(
      `storeData/searchProducts/${MatjrahClient.currentAppID}?search=${searchKeyword}&page=1&limit=12`
    ),
};
