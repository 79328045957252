import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0449a190 = () => interopDefault(import('../pages/app-creation/index.vue' /* webpackChunkName: "pages/app-creation/index" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _50d99d1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _0a8303fe = () => interopDefault(import('../pages/sign-in/index.vue' /* webpackChunkName: "pages/sign-in/index" */))
const _6ce3349a = () => interopDefault(import('../pages/stepper/index.vue' /* webpackChunkName: "pages/stepper/index" */))
const _7cfe97ba = () => interopDefault(import('../pages/dashboard/customers/index.vue' /* webpackChunkName: "pages/dashboard/customers/index" */))
const _023ab946 = () => interopDefault(import('../pages/dashboard/design/index.vue' /* webpackChunkName: "pages/dashboard/design/index" */))
const _800df794 = () => interopDefault(import('../pages/dashboard/homepage/index.vue' /* webpackChunkName: "pages/dashboard/homepage/index" */))
const _301a78a6 = () => interopDefault(import('../pages/dashboard/services/index.vue' /* webpackChunkName: "pages/dashboard/services/index" */))
const _247e50a6 = () => interopDefault(import('../pages/stepper/config/index.vue' /* webpackChunkName: "pages/stepper/config/index" */))
const _f707a9ae = () => interopDefault(import('../pages/stepper/design/index.vue' /* webpackChunkName: "pages/stepper/design/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/app-creation",
    component: _0449a190,
    name: "app-creation___ar___default"
  }, {
    path: "/ar",
    component: _0547af50,
    name: "index___ar"
  }, {
    path: "/dashboard",
    component: _50d99d1d,
    name: "dashboard___ar___default"
  }, {
    path: "/kw",
    component: _0547af50,
    name: "index___kw"
  }, {
    path: "/sign-in",
    component: _0a8303fe,
    name: "sign-in___ar___default"
  }, {
    path: "/stepper",
    component: _6ce3349a,
    name: "stepper___ar___default"
  }, {
    path: "/ar/app-creation",
    component: _0449a190,
    name: "app-creation___ar"
  }, {
    path: "/ar/dashboard",
    component: _50d99d1d,
    name: "dashboard___ar"
  }, {
    path: "/ar/sign-in",
    component: _0a8303fe,
    name: "sign-in___ar"
  }, {
    path: "/ar/stepper",
    component: _6ce3349a,
    name: "stepper___ar"
  }, {
    path: "/dashboard/customers",
    component: _7cfe97ba,
    name: "dashboard-customers___ar___default"
  }, {
    path: "/dashboard/design",
    component: _023ab946,
    name: "dashboard-design___ar___default"
  }, {
    path: "/dashboard/homepage",
    component: _800df794,
    name: "dashboard-homepage___ar___default"
  }, {
    path: "/dashboard/services",
    component: _301a78a6,
    name: "dashboard-services___ar___default"
  }, {
    path: "/kw/app-creation",
    component: _0449a190,
    name: "app-creation___kw"
  }, {
    path: "/kw/dashboard",
    component: _50d99d1d,
    name: "dashboard___kw"
  }, {
    path: "/kw/sign-in",
    component: _0a8303fe,
    name: "sign-in___kw"
  }, {
    path: "/kw/stepper",
    component: _6ce3349a,
    name: "stepper___kw"
  }, {
    path: "/stepper/config",
    component: _247e50a6,
    name: "stepper-config___ar___default"
  }, {
    path: "/stepper/design",
    component: _f707a9ae,
    name: "stepper-design___ar___default"
  }, {
    path: "/ar/dashboard/customers",
    component: _7cfe97ba,
    name: "dashboard-customers___ar"
  }, {
    path: "/ar/dashboard/design",
    component: _023ab946,
    name: "dashboard-design___ar"
  }, {
    path: "/ar/dashboard/homepage",
    component: _800df794,
    name: "dashboard-homepage___ar"
  }, {
    path: "/ar/dashboard/services",
    component: _301a78a6,
    name: "dashboard-services___ar"
  }, {
    path: "/ar/stepper/config",
    component: _247e50a6,
    name: "stepper-config___ar"
  }, {
    path: "/ar/stepper/design",
    component: _f707a9ae,
    name: "stepper-design___ar"
  }, {
    path: "/kw/dashboard/customers",
    component: _7cfe97ba,
    name: "dashboard-customers___kw"
  }, {
    path: "/kw/dashboard/design",
    component: _023ab946,
    name: "dashboard-design___kw"
  }, {
    path: "/kw/dashboard/homepage",
    component: _800df794,
    name: "dashboard-homepage___kw"
  }, {
    path: "/kw/dashboard/services",
    component: _301a78a6,
    name: "dashboard-services___kw"
  }, {
    path: "/kw/stepper/config",
    component: _247e50a6,
    name: "stepper-config___kw"
  }, {
    path: "/kw/stepper/design",
    component: _f707a9ae,
    name: "stepper-design___kw"
  }, {
    path: "/",
    component: _0547af50,
    name: "index___ar___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
