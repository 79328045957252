export const Loader = () => import('../../components/Loader/index.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const ConfigThemeBrands = () => import('../../components/ConfigTheme/Brands/index.vue' /* webpackChunkName: "components/config-theme-brands" */).then(c => wrapFunctional(c.default || c))
export const ConfigThemeCategories = () => import('../../components/ConfigTheme/Categories/index.vue' /* webpackChunkName: "components/config-theme-categories" */).then(c => wrapFunctional(c.default || c))
export const ConfigThemeHeaderAndFooter = () => import('../../components/ConfigTheme/HeaderAndFooter/index.vue' /* webpackChunkName: "components/config-theme-header-and-footer" */).then(c => wrapFunctional(c.default || c))
export const ConfigThemeHomepage = () => import('../../components/ConfigTheme/Homepage/index.vue' /* webpackChunkName: "components/config-theme-homepage" */).then(c => wrapFunctional(c.default || c))
export const ConfigThemeIntro = () => import('../../components/ConfigTheme/Intro/index.vue' /* webpackChunkName: "components/config-theme-intro" */).then(c => wrapFunctional(c.default || c))
export const ConfigThemeMyAccount = () => import('../../components/ConfigTheme/MyAccount/index.vue' /* webpackChunkName: "components/config-theme-my-account" */).then(c => wrapFunctional(c.default || c))
export const ConfigThemeProductCard = () => import('../../components/ConfigTheme/ProductCard/index.vue' /* webpackChunkName: "components/config-theme-product-card" */).then(c => wrapFunctional(c.default || c))
export const ConfigThemeProductPage = () => import('../../components/ConfigTheme/ProductPage/index.vue' /* webpackChunkName: "components/config-theme-product-page" */).then(c => wrapFunctional(c.default || c))
export const ConfigThemeQuickActions = () => import('../../components/ConfigTheme/QuickActions/index.vue' /* webpackChunkName: "components/config-theme-quick-actions" */).then(c => wrapFunctional(c.default || c))
export const DashboardProfile = () => import('../../components/Dashboard/Profile/index.vue' /* webpackChunkName: "components/dashboard-profile" */).then(c => wrapFunctional(c.default || c))
export const DashboardPublish = () => import('../../components/Dashboard/Publish/index.vue' /* webpackChunkName: "components/dashboard-publish" */).then(c => wrapFunctional(c.default || c))
export const DashboardSettings = () => import('../../components/Dashboard/Settings/index.vue' /* webpackChunkName: "components/dashboard-settings" */).then(c => wrapFunctional(c.default || c))
export const GlobalAccordionCard = () => import('../../components/Global/AccordionCard/index.vue' /* webpackChunkName: "components/global-accordion-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalAuthSide = () => import('../../components/Global/AuthSide/index.vue' /* webpackChunkName: "components/global-auth-side" */).then(c => wrapFunctional(c.default || c))
export const GlobalAutocomplete = () => import('../../components/Global/Autocomplete/index.vue' /* webpackChunkName: "components/global-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const GlobalButton = () => import('../../components/Global/Button/index.vue' /* webpackChunkName: "components/global-button" */).then(c => wrapFunctional(c.default || c))
export const GlobalCustomInput = () => import('../../components/Global/CustomInput/index.vue' /* webpackChunkName: "components/global-custom-input" */).then(c => wrapFunctional(c.default || c))
export const GlobalFAQS = () => import('../../components/Global/FAQS/index.vue' /* webpackChunkName: "components/global-f-a-q-s" */).then(c => wrapFunctional(c.default || c))
export const GlobalInput = () => import('../../components/Global/Input/index.vue' /* webpackChunkName: "components/global-input" */).then(c => wrapFunctional(c.default || c))
export const GlobalLaunchStore = () => import('../../components/Global/LaunchStore/index.vue' /* webpackChunkName: "components/global-launch-store" */).then(c => wrapFunctional(c.default || c))
export const GlobalListing = () => import('../../components/Global/Listing/index.vue' /* webpackChunkName: "components/global-listing" */).then(c => wrapFunctional(c.default || c))
export const GlobalLogo = () => import('../../components/Global/Logo/index.vue' /* webpackChunkName: "components/global-logo" */).then(c => wrapFunctional(c.default || c))
export const GlobalPackagePrices = () => import('../../components/Global/PackagePrices/index.vue' /* webpackChunkName: "components/global-package-prices" */).then(c => wrapFunctional(c.default || c))
export const GlobalRegister = () => import('../../components/Global/Register/index.vue' /* webpackChunkName: "components/global-register" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperCustomer = () => import('../../components/Global/SwiperCustomer/index.vue' /* webpackChunkName: "components/global-swiper-customer" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperJoinStores = () => import('../../components/Global/SwiperJoinStores/index.vue' /* webpackChunkName: "components/global-swiper-join-stores" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperMain = () => import('../../components/Global/SwiperMain/index.vue' /* webpackChunkName: "components/global-swiper-main" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperNextStep = () => import('../../components/Global/SwiperNextStep/index.vue' /* webpackChunkName: "components/global-swiper-next-step" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperOpinions = () => import('../../components/Global/SwiperOpinions/index.vue' /* webpackChunkName: "components/global-swiper-opinions" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperPagination = () => import('../../components/Global/SwiperPagination/index.vue' /* webpackChunkName: "components/global-swiper-pagination" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperStore = () => import('../../components/Global/SwiperStore/index.vue' /* webpackChunkName: "components/global-swiper-store" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperVertical = () => import('../../components/Global/SwiperVertical/index.vue' /* webpackChunkName: "components/global-swiper-vertical" */).then(c => wrapFunctional(c.default || c))
export const GlobalTextareaEmoji = () => import('../../components/Global/TextareaEmoji/index.vue' /* webpackChunkName: "components/global-textarea-emoji" */).then(c => wrapFunctional(c.default || c))
export const GlobalWhatsapp = () => import('../../components/Global/Whatsapp/index.vue' /* webpackChunkName: "components/global-whatsapp" */).then(c => wrapFunctional(c.default || c))
export const HomeHero = () => import('../../components/Home/Hero/index.vue' /* webpackChunkName: "components/home-hero" */).then(c => wrapFunctional(c.default || c))
export const HomeOurDealers = () => import('../../components/Home/OurDealers/index.vue' /* webpackChunkName: "components/home-our-dealers" */).then(c => wrapFunctional(c.default || c))
export const HomeStoreManagement = () => import('../../components/Home/StoreManagement/index.vue' /* webpackChunkName: "components/home-store-management" */).then(c => wrapFunctional(c.default || c))
export const HomeVariousMarketing = () => import('../../components/Home/VariousMarketing/index.vue' /* webpackChunkName: "components/home-various-marketing" */).then(c => wrapFunctional(c.default || c))
export const DesignAppColorsPublish = () => import('../../components/Design/AppColorsPublish/index.vue' /* webpackChunkName: "components/design-app-colors-publish" */).then(c => wrapFunctional(c.default || c))
export const DesignDevicePreview = () => import('../../components/Design/DevicePreview/index.vue' /* webpackChunkName: "components/design-device-preview" */).then(c => wrapFunctional(c.default || c))
export const DesignDevicePreviewAdv = () => import('../../components/Design/DevicePreviewAdv/index.vue' /* webpackChunkName: "components/design-device-preview-adv" */).then(c => wrapFunctional(c.default || c))
export const DesignDevicePreviewMain = () => import('../../components/Design/DevicePreviewMain/index.vue' /* webpackChunkName: "components/design-device-preview-main" */).then(c => wrapFunctional(c.default || c))
export const DesignIdentityAndColors = () => import('../../components/Design/IdentityAndColors/index.vue' /* webpackChunkName: "components/design-identity-and-colors" */).then(c => wrapFunctional(c.default || c))
export const DesignThemeCustomization = () => import('../../components/Design/ThemeCustomization/index.vue' /* webpackChunkName: "components/design-theme-customization" */).then(c => wrapFunctional(c.default || c))
export const DesignThemes = () => import('../../components/Design/Themes/index.vue' /* webpackChunkName: "components/design-themes" */).then(c => wrapFunctional(c.default || c))
export const HomepageAnnouncement = () => import('../../components/Homepage/Announcement/index.vue' /* webpackChunkName: "components/homepage-announcement" */).then(c => wrapFunctional(c.default || c))
export const HomepageCarouselProducts = () => import('../../components/Homepage/CarouselProducts/index.vue' /* webpackChunkName: "components/homepage-carousel-products" */).then(c => wrapFunctional(c.default || c))
export const HomepageCountdownTimer = () => import('../../components/Homepage/CountdownTimer/index.vue' /* webpackChunkName: "components/homepage-countdown-timer" */).then(c => wrapFunctional(c.default || c))
export const HomepageFeaturedCategories = () => import('../../components/Homepage/FeaturedCategories/index.vue' /* webpackChunkName: "components/homepage-featured-categories" */).then(c => wrapFunctional(c.default || c))
export const HomepageGIF = () => import('../../components/Homepage/GIF/index.vue' /* webpackChunkName: "components/homepage-g-i-f" */).then(c => wrapFunctional(c.default || c))
export const HomepageGridCategories = () => import('../../components/Homepage/GridCategories/index.vue' /* webpackChunkName: "components/homepage-grid-categories" */).then(c => wrapFunctional(c.default || c))
export const HomepageGridProducts = () => import('../../components/Homepage/GridProducts/index.vue' /* webpackChunkName: "components/homepage-grid-products" */).then(c => wrapFunctional(c.default || c))
export const HomepageSectionTitle = () => import('../../components/Homepage/SectionTitle/index.vue' /* webpackChunkName: "components/homepage-section-title" */).then(c => wrapFunctional(c.default || c))
export const HomepageSlider = () => import('../../components/Homepage/Slider/index.vue' /* webpackChunkName: "components/homepage-slider" */).then(c => wrapFunctional(c.default || c))
export const HomepageStaticBanner = () => import('../../components/Homepage/StaticBanner/index.vue' /* webpackChunkName: "components/homepage-static-banner" */).then(c => wrapFunctional(c.default || c))
export const HomepageStories = () => import('../../components/Homepage/Stories/index.vue' /* webpackChunkName: "components/homepage-stories" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/Layout/Footer/index.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/Layout/Header/index.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const GlobalIconsCounterdownTimer = () => import('../../components/Global/Icons/CounterdownTimer/index.vue' /* webpackChunkName: "components/global-icons-counterdown-timer" */).then(c => wrapFunctional(c.default || c))
export const GlobalIconsSignInMobile = () => import('../../components/Global/Icons/SignInMobile/index.vue' /* webpackChunkName: "components/global-icons-sign-in-mobile" */).then(c => wrapFunctional(c.default || c))
export const GlobalRegisterStepper = () => import('../../components/Global/Register/Stepper/index.vue' /* webpackChunkName: "components/global-register-stepper" */).then(c => wrapFunctional(c.default || c))
export const GlobalRegisterTheme = () => import('../../components/Global/Register/Theme/index.vue' /* webpackChunkName: "components/global-register-theme" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderMenu = () => import('../../components/Layout/Header/Menu/index.vue' /* webpackChunkName: "components/layout-header-menu" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
